
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import ReceivedOrderAddForm from "@/components/user/forms/ReceivedOrderAddForm.vue";
import { ISelectItem } from "@/types";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { ICustomerListRequest, ICustomer } from "@/types/customer";
import { IBranchListRequest, IBranch } from "@/types/branch";
import { IProductListRequest, IProduct } from "@/types/product";
import {
  IReceivedOrder,
  IReceivedOrderCreateRequest,
  IReceivedOrderUpdateRequest
} from "@/types/received_order";
import { IProductReceivedOrder } from "@/types/product_received_order";
import { ProductReceivedOrder } from "@/models/product_received_order";
import {
  ReceivedOrderCreateRequest,
  ReceivedOrderUpdateRequest
} from "@/models/received_order";
import { ReceivedOrdersManagementRequest } from "@/models/received_orders_management";
import { IReceivedOrdersManagementRequest } from "@/types/received_orders_management";
import {
  ICustomerProduct,
  ICustomerProductListRequest
} from "@/types/customer_product";
import { ICustomerPriceTagListRequest } from "@/types/customer_price_tag";
import { IPriceTag, IPriceTagListRequest } from "@/types/price_tag";
import { PriceTagReceivedOrder } from "@/models/price_tag_received_order";
import { IUser } from "@/types/user";

@Component({ components: { ReceivedOrderAddForm } })
export default class extends Vue {
  //received_order
  @Action("received_order/userGet")
  public getReceivedOrder!: (received_order_id: number) => Promise<boolean>;

  @Action("received_order/userCreate")
  public create!: (params: IReceivedOrderCreateRequest) => Promise<boolean>;

  @Action("received_order/userUpdate")
  public update!: (data: {
    received_order_id: number;
    params: IReceivedOrderUpdateRequest;
  }) => Promise<boolean>;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //customer
  @Action("customer/userGetList")
  public getCustomers!: (params: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/userGetList")
  public getBranches!: (params: IBranchListRequest) => Promise<boolean>;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //maker
  @Action("maker/userGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //product
  @Action("product/userGetProductSegments")
  public getProductSegments!: (params: IProductListRequest) => Promise<boolean>;

  @Getter("product/filterdSelectItem")
  public makerFilterdSelectItem!: (maker_id: number) => ISelectItem[];

  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  // customer_product
  @Action("customer_product/userGetList")
  public getCustomerProducts!: (
    params: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/filterdSelectItem")
  public customerProductFilterdSelectItem!: (
    customer_id: number,
    product_id: number
  ) => ISelectItem[];

  @Getter("customer_product/find")
  public findCustomerProduct!: (id: number) => ICustomerProduct;

  @Mutation("customer_product/clear")
  public clearCustomerProduct!: () => void;

  // price_tag
  @Action("price_tag/userGetList")
  public getPriceTags!: (request: IPriceTagListRequest) => Promise<boolean>;

  @Getter("price_tag/selectItem")
  public priceTagList!: ISelectItem[];

  @Getter("price_tag/find")
  public findPriceTag!: (id: number) => IPriceTag;

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //customer_price_tag
  @Action("customer_price_tag/userGetList")
  public getCustomerPriceTags!: (
    params: ICustomerPriceTagListRequest
  ) => Promise<boolean>;

  @Getter("customer_price_tag/filterdSelectItem")
  public customerPriceTagFilterdSelectItem!: (
    customer_id: number,
    price_tag_id: number
  ) => ISelectItem[];

  @Getter("customer_price_tag/find")
  public findCustomerPriceTag!: (id: number) => ICustomerProduct;

  @Mutation("customer_price_tag/clear")
  public clearCustomerPriceTag!: () => void;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //変数定義
  public received_order_status = Vue.prototype.$receivedOrderStatus;
  public order_status_list = [
    { value: this.received_order_status.new_received_order, text: "新規受注" },
    { value: this.received_order_status.received_ordered, text: "受注済み" },
    {
      value: this.received_order_status.fixed_delivery_date,
      text: "納期回答済"
    },
    {
      value: this.received_order_status.cancel_received_order,
      text: "受注キャンセル"
    }
  ];
  public received_order_id = 0;
  public maker_id: number[] = [];
  public productOptions: ISelectItem[][] = [];
  public customerProductOptions: ISelectItem[][] = [];
  public customerPriceTagOptions: ISelectItem[][] = [];
  public units: (string | null)[] = [];
  public is_minus: number[] = [];
  public is_disabled: boolean[] = [true];
  public is_readonly: boolean[] = [false];
  public is_disabled_price_tag: boolean[] = [true];
  public is_readonly_price_tag: boolean[] = [false];
  public add_received_order = false;
  public uneditable = false;
  public submit_dialog = false;
  public valid = true;
  public valid_product_received_order = true;
  public child_valid = true;
  public lazy = false;
  public menu = {
    order_date: false,
    desired_delivery_date: false,
    fixed_delivery_date: false
  };

  //パラメータ定義
  public params: IReceivedOrderUpdateRequest = new ReceivedOrderUpdateRequest();
  public create_params: IReceivedOrderCreateRequest =
    new ReceivedOrderCreateRequest();
  public received_orders_management_params: IReceivedOrdersManagementRequest =
    new ReceivedOrdersManagementRequest();

  //ルール設定
  public rules = {
    order_no: [
      (v: string) => !!v || "顧客注文番号が無い場合はメモ等を入力してください"
    ],
    order_date: [(v: string) => !!v || "受注受付日は必須です"],
    order_status: [(v: number) => !!v || "受注ステータスは必須です"],
    customer_id: [(v: number) => !!v || "顧客は必須です"],
    branch_id: [(v: number) => !!v || "支店は必須です"],
    maker_id: [(v: number) => !!v || "メーカは必須です"],
    quantity: [
      (v: number) => !!v || "数量は必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) => /^([1-9]\d*|0)$/.test(v) || "整数で入力してください"
    ],
    selling_unit_price: [
      (v: number) => !!v || v === 0 || "単価は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.received_order_id = Number(this.$route.params.received_order_id);
    this.clearReceivedOrder();
    this.clearCustomer();
    this.clearBranch();
    this.clearMaker();
    this.clearProduct();
    this.clearCustomerProduct();
    this.clearPriceTag();
    this.clearCustomerPriceTag();

    await Promise.all([
      this.getReceivedOrder(this.received_order_id),
      this.getCustomers({ per_page: 0, customer_user: this.user.id }),
      this.getMakers({ per_page: 0 }),
      this.getCustomerProducts({ per_page: 0 }),
      this.getPriceTags({ per_page: 0 }),
      this.getCustomerPriceTags({ per_page: 0 })
    ]);

    await Promise.all([
      this.getBranches({
        customer_id: this.received_order.customer_id,
        per_page: 0
      })
    ]);

    this.setDefault();

    this.$nextTick(function () {
      (
        this.$refs.received_order_form as Vue & { validate: () => boolean }
      ).validate();
      (
        this.$refs.product_received_order_form as Vue & {
          validate: () => boolean;
        }
      ).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setDefault() {
    this.received_orders_management_params.management_no =
      this.received_order.management_no;
    this.params.craetateFromReceivedOrder(this.received_order);

    // 受注ステータスが新規受注の場合、赤文字にする
    this.changeStatusColor();

    // 選択肢等の用意
    for (let [index, single] of this.received_order.products.entries()) {
      this.maker_id.push(single.maker_id);

      await this.getProductSegments({
        maker_id: single.maker_id,
        per_page: 0
      });

      const filterdItems = this.makerFilterdSelectItem(single.maker_id);
      this.productOptions.push(filterdItems);

      const customerProductFilterdItems = this.customerProductFilterdSelectItem(
        this.params.customer_id,
        single.product_id
      );

      this.is_disabled[index] = customerProductFilterdItems.length == 0;
      this.is_readonly[index] = customerProductFilterdItems.length == 1;
      this.customerProductOptions.push(customerProductFilterdItems);

      if (this.is_readonly[index]) {
        this.params.product_received_orders[index].customer_product_id = Number(
          this.customerProductOptions[index][0].value
        );
      }

      const product = this.findProduct(single.product_id);

      const unit = product ? product.unit : "";
      this.units.push(unit);
    }

    for (let [
      index,
      single
    ] of this.received_order.price_tag_received_order.entries()) {
      const customerPriceTagFilterdSelectItems =
        this.customerPriceTagFilterdSelectItem(
          this.params.customer_id,
          single.price_tag_id
        );

      this.isMinusCheck(single.price_tag_id, index);
      this.is_disabled_price_tag[index] =
        customerPriceTagFilterdSelectItems.length == 0;
      this.is_readonly_price_tag[index] =
        customerPriceTagFilterdSelectItems.length == 1;
      this.customerPriceTagOptions.push(customerPriceTagFilterdSelectItems);

      if (this.is_readonly_price_tag[index]) {
        this.params.price_tag_received_orders[index].customer_price_tag_id =
          Number(this.customerPriceTagOptions[index][0].value);
      }
    }
  }

  //バリデーションチェック
  public updated() {
    this.$nextTick(function () {
      (
        this.$refs.product_received_order_form as Vue & {
          validate: () => boolean;
        }
      ).validate();
    });
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    // update --------------------------
    //諸経費配列の整理
    if (
      !this.params.price_tag_received_orders.some(item => item.price_tag_id > 0)
    ) {
      this.params.price_tag_received_orders.splice(0);
    } else {
      this.params.price_tag_received_orders =
        this.params.price_tag_received_orders.filter(item => {
          return item.price_tag_id;
        });
    }

    result = await this.update({
      received_order_id: this.received_order_id,
      params: this.params
    });
    // -----------------------------------

    // create --------------------------
    if (this.add_received_order) {
      //諸経費配列の整理
      for (let [, order] of this.create_params.orders.entries()) {
        if (
          !order.price_tag_received_orders.some(item => item.price_tag_id > 0)
        ) {
          order.price_tag_received_orders.splice(0);
        } else {
          order.price_tag_received_orders =
            order.price_tag_received_orders.filter(item => {
              return item.price_tag_id;
            });
        }
      }

      result = await this.create(this.create_params);
    }
    // -----------------------------------

    if (result) {
      this.$router.go(-1);
    }
  }

  //顧客が選択された際の挙動
  public async customerSelected() {
    this.clearBranch();
    this.params.branch_id = null;

    if (this.params.customer_id) {
      await Promise.all([
        this.getBranches({
          customer_id: this.params.customer_id,
          per_page: 0
        })
      ]);

      this.params.product_received_orders.forEach(
        (product_received_order, index) => {
          if (product_received_order.product_id) {
            this.productSelected(index);
          }
        }
      );

      this.params.price_tag_received_orders.forEach(
        (price_tag_received_order, index) => {
          if (price_tag_received_order.price_tag_id) {
            this.priceTagSelected(index);
          }
        }
      );
    }
  }

  //メーカが選択された際の挙動
  public async makerSelected(index: number) {
    this.params.product_received_orders[index].customer_product_id = null;

    await this.getProductSegments({
      maker_id: this.maker_id[index],
      per_page: 0
    });

    this.$set(
      this.productOptions,
      index,
      this.makerFilterdSelectItem(this.maker_id[index])
    );
  }

  //商品が選択された際の挙動
  public productSelected(index: number) {
    this.params.product_received_orders[index].customer_product_id = null;
    const product = this.findProduct(
      this.params.product_received_orders[index].product_id
    );

    this.params.product_received_orders[index].selling_unit_price = product
      ? product.unit_price
        ? Number(product.unit_price.toFixed(2))
        : null
      : null;

    this.units[index] = product ? product.unit : null;

    this.$set(
      this.customerProductOptions,
      index,
      this.customerProductFilterdSelectItem(
        this.params.customer_id,
        this.params.product_received_orders[index].product_id
      )
    );

    this.is_disabled[index] =
      Object.keys(this.customerProductOptions[index]).length == 0;

    this.is_readonly[index] =
      Object.keys(this.customerProductOptions[index]).length == 1;

    if (this.is_readonly[index]) {
      this.params.product_received_orders[index].customer_product_id = Number(
        this.customerProductOptions[index][0].value
      );
    }
  }

  //諸経費が選択された際の挙動
  public priceTagSelected(index: number) {
    this.$set(
      this.customerPriceTagOptions,
      index,
      this.customerPriceTagFilterdSelectItem(
        this.params.customer_id,
        this.params.price_tag_received_orders[index].price_tag_id
      )
    );
    this.is_disabled_price_tag[index] =
      Object.keys(this.customerPriceTagOptions[index]).length == 0;

    this.is_readonly_price_tag[index] =
      Object.keys(this.customerPriceTagOptions[index]).length == 1;

    if (this.is_readonly_price_tag[index]) {
      this.params.price_tag_received_orders[index].customer_price_tag_id =
        Number(this.customerPriceTagOptions[index][0].value);
    }
  }

  //受注商品追加
  public appendProductReceivedOrderList() {
    this.params.product_received_orders.push(new ProductReceivedOrder());
    this.is_disabled.push(true);
    this.is_readonly.push(false);
  }

  //受注商品削除
  public deleteProductReceivedOrderList(index: number) {
    this.params.product_received_orders.splice(index, 1);
    this.maker_id.splice(index, 1);
    this.productOptions.splice(index, 1);
    this.customerProductOptions.splice(index, 1);
    this.units.splice(index, 1);
    this.is_disabled.splice(index, 1);
    this.is_readonly.splice(index, 1);
  }

  //諸経費追加
  public appendPriceTagReceivedOrderList() {
    this.params.price_tag_received_orders.push(new PriceTagReceivedOrder());
    this.is_disabled_price_tag.push(true);
    this.is_readonly_price_tag.push(false);
  }

  //諸経費削除
  public deletePriceTagReceivedOrderList(index: number) {
    this.params.price_tag_received_orders.splice(index, 1);
    this.customerPriceTagOptions.splice(index, 1);
    this.is_disabled_price_tag.splice(index, 1);
    this.is_readonly_price_tag.splice(index, 1);
    this.is_minus.splice(index, 1);

    if (this.params.price_tag_received_orders.length == 0) {
      this.params.price_tag_received_orders.push(new PriceTagReceivedOrder());
      this.is_disabled_price_tag.push(true);
      this.is_readonly_price_tag.push(false);
    }
  }

  //小計金額計算
  public get amount() {
    let amount = 0;
    this.params.product_received_orders.forEach(
      item => (amount += item.product_amount)
    );

    this.params.price_tag_received_orders.forEach((price_tag, index) => {
      this.is_minus[index]
        ? (amount -= Number(price_tag.price))
        : (amount += Number(price_tag.price));
    });

    return (this.params.amount = Number(amount.toFixed(2)));
  }

  //商品合計金額計算(バックエンド側で計算する場合は不要)
  public calcAmount(
    index: number,
    product_received_orders: IProductReceivedOrder
  ) {
    this.params.product_received_orders[index].amount = Number(
      product_received_orders.product_amount.toFixed(2)
    );
  }

  //price_tagのis_minusチェック
  public isMinusCheck(price_tag_id: number, index: number) {
    this.is_minus[index] = price_tag_id
      ? this.findPriceTag(price_tag_id).is_minus
      : 0;
  }

  //productバリデーション
  public productRule(v: number) {
    const countSameProductId = this.params.product_received_orders.filter(
      product_received_order => product_received_order.product_id == v
    ).length;
    if (countSameProductId >= 2) {
      return "同じ商品は選択できません";
    }

    return true;
  }

  //price_tag_idバリデーション
  public prriceTagRule(v: number) {
    const countSamePriceTagId = this.params.price_tag_received_orders.filter(
      price_tag => price_tag.price_tag_id == v
    ).length;
    if (countSamePriceTagId >= 2) {
      return "同じ諸経費名は選択できません";
    }

    return true;
  }

  //priceバリデーション
  public priceRule(v: number) {
    if (v < 0) {
      return "0以下は入力できません";
    }
    if (!/^([1-9]\d*|0)(\.\d{1,2})?$/.test(String(v))) {
      return "小数点以下2桁までで入力してください";
    }

    return true;
  }

  //fixed_delivery_dateバリデーション
  public fixedDeliveryDateRule() {
    if (
      !this.params.fixed_delivery_date &&
      (this.params.order_status ==
        this.received_order_status.fixed_delivery_date ||
        this.params.order_status ==
          this.received_order_status.estimated_shipment)
    ) {
      return "回答納品日は必須です";
    }

    return true;
  }

  // 受注ステータスが新規受注の場合、赤文字にする
  public changeStatusColor() {
    if (
      this.params.order_status ==
      Vue.prototype.$receivedOrderStatus.new_received_order
    ) {
      document
        .querySelector(".status-select input")
        ?.classList.add("red--text");
    } else if (
      document
        .querySelector(".status-select input")
        ?.classList.contains("red--text")
    ) {
      document
        .querySelector(".status-select input")
        ?.classList.remove("red--text");
    }
  }

  // 追加の顧客注文が全て削除されたかチェック
  public createParams(create_params: IReceivedOrderCreateRequest) {
    this.create_params = create_params;
  }

  // 追加の顧客注文が全て削除されたかチェック
  public deletedCheck(is_deleted: boolean) {
    if (is_deleted) {
      this.add_received_order = false;
      this.child_valid = true;
    }
  }

  /* 受注ステータスがキャンセルの場合、登録済の受注データを再セット
      ステータス選択以外は全てdisabled */
  public reSetDefault() {
    if (
      this.params.order_status !=
      Vue.prototype.$receivedOrderStatus.cancel_received_order
    ) {
      this.uneditable = false;
      return;
    }

    // 受注商品配列リセット
    this.params.product_received_orders.splice(0);
    this.maker_id.splice(0);
    this.productOptions.splice(0);
    this.customerProductOptions.splice(0);
    this.units.splice(0);
    this.is_disabled.splice(0);
    this.is_readonly.splice(0);
    this.is_disabled.push(true);
    this.is_readonly.push(false);

    // 諸経費配列リセット
    this.params.price_tag_received_orders.splice(0);
    this.customerPriceTagOptions.splice(0);
    this.is_disabled_price_tag.splice(0);
    this.is_readonly_price_tag.splice(0);
    this.is_minus.splice(0);
    this.is_disabled_price_tag.push(true);
    this.is_readonly_price_tag.push(false);

    // 受注データ再セット
    this.setDefault();

    // キャンセルステータス入れ直し
    this.params.order_status =
      Vue.prototype.$receivedOrderStatus.cancel_received_order;

    // ステータス以外の全項目disabled
    this.uneditable = true;
  }
}
